import React from "react";
import { Link } from "react-router-dom";
import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import Footer2 from "components/Footers/Footer2";


export default function Services2() {
  return (
    <>
      <Indexnavbar2 fixed />
      

      <Footer2 />
    </>
  );
}
