import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import React, { FC, useState, Fragment, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer2 from "components/Footers/Footer2";


export default function Homepage1() {
  return (
    <section>
      <Indexnavbar2 />

      

      <Footer2 />
    </section>
  );
}
