import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import google from "assets/img/google.png"

const Profile = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1100,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    nextArrow: null, // Remove default next arrow
    prevArrow: null, // Remove default prev arrow
  };

  return (
    <section>
      <div className="relative">
        <Slider {...settings}>
          

          <div>
            <div className="xl:w-11/12 xl:p-6 border-box lg:w-11/12 lg:p-6 md:w-11/12 md:w-11/12 md:p-6 w-11/12 p-6 h-76">
              <p
                className="poppins xl:text-sm text-white lg:text-sm md:text-sm text-sm"
                style={{ textAlign: "justify" }}
              >
                Nice company to deal with. I provided everything which I needed from NewZealand
                Ram made a tailor made solution and helped me through the process in making my website.
              </p>
              <div className="xl:mt-36 lg:mt-32 md:mt-32 mt-32 flex justify-between items-center">
                <div className="">
                  <h4 className="poppins xl:text-base text-yellow font-semibold lg:text-sm md:text-sm text-sm">
                    Shashidhar Pola
                  </h4>
                  {/* <p className="poppins xl:text-base text-bluee font-semibold lg:text-sm md:text-sm text-sm">
                    CEO, Universalnyc
                  </p> */}
                </div>
                <div className="">
                  <img src={google} className="w-24"></img>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="poppins xl:w-11/12 xl:p-6 lg:w-11/12 lg:p-6 border-box md:w-11/12 md:p-6 w-11/12 p-6 h-76">
              <p
                className="poppins xl:text-sm text-white lg:text-sm md:text-sm text-sm"
                style={{ textAlign: "justify" }}
              >
                Working with Ram was an exceptional experience. Their expertise and creativity turned my vision into a
                stunning website. They were attentive to my needs, responsive to my feedback, and delivered a website that
                exceeded my expectations. I appreciate their professionalism and dedication to making my online presence a
                success. I highly recommend Elegant Carve for anyone seeking top-notch web design services.
              </p>
              <div className="xl:mt-10 lg:mt-0 md:mt-10 mt-10 flex justify-between items-center">
                <div className="">
                  <h4 className="poppins xl:text-base text-yellow font-semibold lg:text-sm md:text-sm text-sm">
                    Dr. Kirana Tm
                  </h4>
                  {/* <p className="poppins xl:text-base text-bluee font-semibold lg:text-sm md:text-sm text-sm">
                    CEO, Universalnyc
                  </p> */}
                </div>
                <div className="">
                  <img src={google} className="w-24"></img>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="xl:w-11/12 xl:p-6 border-box lg:w-11/12 lg:p-6 md:w-11/12 md:p-6 w-11/12 p-6 h-76">
              <p
                className="poppins xl:text-sm text-white lg:text-sm md:text-sm text-sm"
                style={{ textAlign: "justify" }}
              >
                Mr.Ram gives a excellent outcome . Elegant carve understands the client requirements and makes things possible.
                I'm with elegant since past 4 years. They are the best web designers and trust worthy.
              </p>
              <div className="xl:mt-32 lg:mt-24 md:mt-32 mt-32 flex justify-between items-center">
                <div className="">
                  <h4 className="poppins xl:text-base text-yellow font-semibold lg:text-sm md:text-sm text-sm">
                    Linda magdaline
                  </h4>

                </div>
                <div className="">
                  <img src={google} className="w-24"></img>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="xl:w-11/12 xl:p-6 border-box lg:w-11/12 lg:p-6 md:w-11/12 md:p-6 w-11/12 p-6 h-76">
              <p
                className="poppins xl:text-sm text-white lg:text-sm md:text-sm text-sm"
                style={{ textAlign: "justify" }}
              >
                Excellent service by Ram and Team. I will definitely recommend them if anyone is looking for web design work. 
              </p>
              <div className="xl:mt-40 lg:mt-40 md:mt-40 mt-32 flex justify-between items-center">
                <div className="">
                  <h4 className="poppins xl:text-base text-yellow font-semibold lg:text-sm md:text-sm text-sm">
                    Kapil Mahey
                  </h4>
                  {/* <p className="poppins xl:text-base text-bluee font-semibold lg:text-sm md:text-sm text-sm">
                    CEO, Universalnyc
                  </p> */}
                </div>
                <div className="">
                  <img src={google} className="w-24"></img>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default Profile;
