import React, { useState } from "react";
import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import Footer2 from "components/Footers/Footer2";


const CancellationPolicy = () => {
   

  return (
    <>
      <Indexnavbar2 fixed />

      <Footer2 />
    </>
  );
};

export default CancellationPolicy;
