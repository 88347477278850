import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ecbrand1 from "assets/img/ecbrand1.webp";
import elegantcarveclients from "assets/img/elegantcarveclients.webp"

const GallerySlider1 = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 50000, // Slow speed for continuous scroll
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 0, // No delay between scrolls
    cssEase: "linear", // Continuous linear animation
  };

  const sliderContainerStyle = {
    overflow: "hidden",
    width: "100%",
  };

  const imageContainerStyle = {
    display: "flex",
    flexDirection: "row", // Ensure images are in a row
    animation: "scroll-left 3s linear infinite", // Apply animation for continuous scroll
  };

  const movingImageStyle = {
    width: "100%",
    flexShrink: 0,
  };

  const keyframesStyle = `
    @keyframes scroll-left {
      100% {
        transform: translateX(100%);
      }
      0% {
        transform: translateX(0%);
      }
    }
  `;

  return (
    <section>
      <style>{keyframesStyle}</style>
      <div style={sliderContainerStyle}>
        <Slider {...settings}>
          <div style={imageContainerStyle} className="">
            <img style={movingImageStyle} src={elegantcarveclients} alt="Brand" />
          </div>
          <div style={imageContainerStyle}>
            <img style={movingImageStyle} src={elegantcarveclients} alt="Brand" />
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default GallerySlider1;
