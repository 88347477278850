import Footer2 from "components/Footers/Footer2";
import React from "react";
import Indexnavbar2 from "components/Navbars/Indexnavbar2";



export default function About3() {
    return(
        <section>
        <Indexnavbar2 />
        
       

            


          
        <Footer2 />
    </section>
  );
}