import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import elegantcarveicon from "assets/img/elegantcarveicon.webp";
import ecservices11 from "assets/img/ecservices11.webp";
import ecservices12 from "assets/img/ecservices12.webp";
import portfolio1 from "assets/img/portfolio1.webp";
import portfolio2 from "assets/img/portfolio2.webp";
import portfolio3 from "assets/img/portfolio3.webp";
import portfolio4 from "assets/img/portfolio4.webp";
import portfolio5 from "assets/img/portfolio5.webp";
import portfolio6 from "assets/img/portfolio6.webp";
import portfolio7 from "assets/img/portfolio7.webp";
import portfolio8 from "assets/img/portfolio8.webp";
import ecservices15 from "assets/img/ecservices15.png";

// Define CustomPrevArrow and CustomNextArrow components outside of SliderPage

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: "10px", zIndex: 1 }}
      onClick={onClick}
    >
      {/* custom left arrow icon or text */}
      {/* <span style={{ color: "blue", fontSize: "24px" }}>{"<"}</span> */}
      <span>{"<"}</span>
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right: "10px", zIndex: 1 }}
      onClick={onClick}
    >
      {/* custom right arrow icon or text with red color */}
      {/* <span style={{ color: "red", backgroundColor: "red", fontSize: "24px" }}>{">"}</span> */}
      <span>{">"}</span>
    </div>
  );
};

const SliderPage = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 4.7,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: false,
    centerPadding: "0px", // Ensures no padding on the sides
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <section>
      <div>
        <Slider {...settings}>
          <div>
            <div class="flex justify-center">
              <div class="image-container">
                <img class="image-main" src={portfolio1} alt="Image 11" />
                <img class="image-overlay" src={elegantcarveicon} alt="Image 10" />
              </div>
            </div>
          </div>

          
          

          <div>
            <div class="flex justify-center">
              <div class="image-container">
                <img class="image-main" src={portfolio2} alt="Image 11" />
                <img class="image-overlay" src={elegantcarveicon} alt="Image 10" />
              </div>
            </div>
          </div>


          




          <div>
            <div class="flex justify-center">
              <div class="image-container">
                <img class="image-main" src={portfolio3} alt="Image 11" />
                <img class="image-overlay" src={elegantcarveicon} alt="Image 10" />
              </div>
            </div>
          </div>


          <div>
            <div class="flex justify-center">
              <div class="image-container">
                <img class="image-main" src={portfolio5} alt="Image 11" />
                <img class="image-overlay" src={elegantcarveicon} alt="Image 10" />
              </div>
            </div>
          </div>


          <div>
            <div class="flex justify-center">
              <div class="image-container">
                <img class="image-main" src={portfolio4} alt="Image 11" />
                <img class="image-overlay" src={elegantcarveicon} alt="Image 10" />
              </div>
            </div>
          </div>

          

          

          <div>
            <div class="flex justify-center">
              <div class="image-container">
                <img class="image-main" src={portfolio8} alt="Image 11" />
                <img class="image-overlay" src={elegantcarveicon} alt="Image 10" />
              </div>
            </div>
          </div>

          

          <div>
            <div class="flex justify-center">
              <div class="image-container">
                <img class="image-main" src={portfolio7} alt="Image 11" />
                <img class="image-overlay" src={elegantcarveicon} alt="Image 10" />
              </div>
            </div>
          </div>

          <div>
            <div class="flex justify-center">
              <div class="image-container">
                <img class="image-main" src={portfolio6} alt="Image 11" />
                <img class="image-overlay" src={elegantcarveicon} alt="Image 10" />
              </div>
            </div>
          </div>

        </Slider>
      </div>
    </section>
  );
};

export default SliderPage;
